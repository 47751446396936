import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero/hero";
import Row from "../components/row/row";
import { StaticImage } from "gatsby-plugin-image";
import * as ContactStyle from './contact.module.css'
import Form from "../components/form/form";
import MandalaImg from '../images/mandala3.png'

export default function Contact() {
    return (
        <Layout pageTitle={'Contact'}>
            <Hero hasButton={false}>
                <StaticImage
                    src={`../images/contact.jpg`}
                    alt="A picture of Heidi"
                    placeholder="blurred"
                    layout="fullWidth"
                    objectFit="cover"
                    quality={100}
                />
                {/* <div className="darken-image"></div> */}
                {/* <StaticImage
                    src={`../images/mandala4.png`}
                    layout="fixed"
                    width={75}
                    className={AboutStyle.image2} 
                /> */}
            </Hero>
            <div className="block-3x"></div>
            <Row >
                <div className="column is-3"></div>
                <div className="column is-6 has-text-centered is-family-secondary">
                    <h1 className={'title is-3'}>Contact Heidi</h1>
                    <Form type={`contact`}></Form>
                </div>
                <div className="column is-3 pl-6 pr-6"></div>
            </Row>
            <Row styles={`mt-6 mb-2 pt-6 pb-6 has-background-primary has-text-white is-family-fancy`}>
                <div className="column is-2"></div>
                <div className="column is-7">
                    {/* <p className="title is-size-2  has-text-white  has-text-centered has-text-weight-semibold">I’d really love to connect with you.</p>
                    <p className="is-family-primary">The best way to connect with me to find out what is new and in the offering is through Facebook, Instagram and Youtube. </p>
                    <p className="is-family-primary mt-5">That said, I am always happy to speak by phone to find our more about your interest in yoga and to figure out how I might be able to help. If you’d like to stay connected through my weekly inspirational offerings and tips, please sign up here:</p> */}
                    <p className="is-size-1  has-text-white  has-text-centered ml-3">To keep the body in good health is a duty … Otherwise we shall not be able to keep our mind strong and clear.</p>
                    <p className="is-size-3  has-text-white  has-text-centered mt-5">- Buddha</p>
                </div>
                <div className={`column is-3 ${ContactStyle.mandalaDiv}`}>
                     <img src={MandalaImg} className={`${ContactStyle.mandala}`}/> 
                </div>
            </Row>
        </Layout>
    )
}